// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "foundation-sites"

//import "./hello_coffee.coffee"
import '../src/font-awesome.js';


// Import css from js for webpack to process it correctly
import '../css/application.css'

// Add Choices Dropdown
const Choices = require('choices.js')
document.addEventListener("turbolinks:load", function () {
    var dropDownSelects = new Choices('#dropdown-choice-select')
})

require("src/application")

require("chartkick/chart.js")

$(document).on('turbolinks:load', () => {
    $(document).foundation();
})